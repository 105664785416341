// extracted by mini-css-extract-plugin
export var gallery = "gallery-module--gallery--GGa7D";
export var main = "gallery-module--main--zUD8N";
export var main__sidebar = "gallery-module--main__sidebar--Um1zz";
export var main__container = "gallery-module--main__container--Lkx5i";
export var main__swiper = "gallery-module--main__swiper--dhS-x";
export var main__slide = "gallery-module--main__slide--e+BUd";
export var main__slideImg = "gallery-module--main__slide--img--m9d9v";
export var controls = "gallery-module--controls--JCiHY";
export var controls__swiper = "gallery-module--controls__swiper--TQhNK";
export var controls__slide = "gallery-module--controls__slide--mxFiO";
export var controls__slideImg = "gallery-module--controls__slide--img--THv67";
export var box = "gallery-module--box--17UMF";