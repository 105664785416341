import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Thumbs, A11y, Keyboard, Mousewheel } from "swiper"
import "swiper/css/bundle"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import SingleBox from "../components/singlebox"
import Button from "../components/button"

import {
  gallery,
  main,
  main__container,
  main__swiper,
  main__slide,
  main__slideImg,
  controls,
  controls__swiper,
  controls__slide,
  controls__slideImg,
  box,
} from "./gallery.module.scss"




const Gallery = ({data}) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null)

  let photos = data.allIndexImagesJson.nodes
  let stories = data.allMdx.nodes

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <Layout pageTitle="Gallery">
      <div className={gallery}>
        <div className={main}>
          <div className={main__container}>
            <Swiper
              className={main__swiper}
              slidesPerView={1}
              spaceBetween={10}
              modules={[Thumbs, Navigation, A11y, Keyboard]}
              thumbs={{ swiper: thumbsSwiper }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              a11y
              keyboard={{
                enabled: true
              }}
            >
              {photos.map((x, i) => (
                <SwiperSlide key={"gal" + i} className={main__slide}>
                  <GatsbyImage
                    className={main__slideImg}
                    objectFit="contain"
                    objectPosition={"0 0"}
                    image={getImage(photos[i].image.originals)}
                    alt={photos[i].text}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Sidebar type="left">
            {stories.map((story, i) => (
              <SingleBox
                className={box}
                type="story"
                data={story}
                key={i + `st`}
              />
            ))}
            <SingleBox className={box} type="empty"></SingleBox>
          </Sidebar>
          <Sidebar>
            <Button ref={navigationPrevRef}></Button>
            <Button ref={navigationNextRef} type="right"></Button>
          </Sidebar>
        </div>
        <div className={controls}>
          <Swiper
            modules={[Thumbs, Mousewheel]}
            watchSlidesProgress
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            className={controls__swiper}
            slidesPerView="auto"
            mousewheel={true}
          >
            {photos.map((x, i) => (
              <SwiperSlide key={"thumb" + i} className={controls__slide}>
                <GatsbyImage
                  className={controls__slideImg}
                  image={getImage(photos[i].image.thumbnails)}
                  alt={photos[i].title}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
      allIndexImagesJson {
        nodes {
          title
          text
          image {
            thumbnails: childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                height: 100
                width: 100
                quality: 30
              )
            }
            originals: childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920)
            }
          }
        }
      }
      allMdx(sort: {order: ASC, fields: [frontmatter___title]}) {
        nodes {
          slug
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
`

export default Gallery
