import * as React from 'react'
import { button, buttonRight } from './button.module.scss'
import Back from '../images/svg/back.svg'
import Next from '../images/svg/next.svg'

const Button = React.forwardRef(({type, ...props}, ref) => {
    if (type === "right") {
        return (
            <button ref={ref} className={`${button} ${buttonRight}`}>
                <Next></Next>
            </button>
        )
    } else {
        return (
            <button  ref={ref} className={`${button}`}>
                <Back></Back>
            </button>
        )
    }

})

export default Button